.cart {
    width: 100%;
    margin-top: 46px;
    padding: 0 18px;
    box-sizing: border-box;
    padding: 20px 18px 140px 18px;
}

.cart__title-box {
    display: flex;
    justify-content: space-between;
}

.cart__title {
    color: #DEB75D;
    font-family: "Manrope";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 114.286% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
    padding: 20px 0 30px 0;
}

.cart__product {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 12px 0 26px 0;
    border-top: solid 1px rgba(138, 138, 163, 0.30);
}

.cart__product__title {
    color: #FFF;
    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
    letter-spacing: 0.72px;
    margin-top: 2px;
}

.cart__product__price {
    color: #C3A08D;
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 120% */
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-top: 12px;
}

.cart__product__price-ruble {
    color: #C3A08D;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.cart__total-box {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 43px;
    border-top: solid 1px rgba(138, 138, 163, 0.30);
}

.cart__total {
    color: #FFF;
    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.cart__total__number {
    color: #FFF;
    font-family: "Manrope";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 107.692% */
    letter-spacing: 1.04px;
    text-transform: uppercase;
    margin-right: 4px;
}

.cart__total__number-ruble {
    color: #FFF;
    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    /* 155.556% */
    letter-spacing: 0.72px;
    text-transform: uppercase;
}

.cart__button-send {
    border-radius: 12px;
    border: 2px solid #DEB75D;
    color: #DEB75D;
    font-family: "Manrope";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */
    position: fixed;
    bottom: 32px;
    left: 18px;
    right: 18px;

    display: flex;
    max-width: 100vw;
    height: 60px;
    padding: 18px 0px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    background: #0D0D0D;
    transition: opacity 0.2s ease-in-out;
}

.cart__button-send_disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}

.cart__product__counter-box {
    display: flex;
    align-items: center;
    gap: 16px;
}

.cart__product__counter-box__delete {
    align-self: flex-end;
    text-align: end;
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: end;

    color: #FE7D20;
    font-family: "Manrope";
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    /* 160% */
    padding-bottom: 18px;
}

.cart__counter-btn {
    transition: opacity 0.2s ease-in-out;
}

.cart__counter-btn_disabled {
    opacity: 0.6;
    pointer-events: none;
    user-select: none;
}
