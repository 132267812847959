.rules {
    position: relative;
    background-color: #0D0D0D;
    z-index: 1000;
    width: 100%;
    max-width: 390px;
    height: 100vh;
    padding: 132px 20px 120px 20px;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow: scroll;
}

.rules__hand-logo {
    position: absolute;
    max-width: 330px;
    max-height: 240px;
    right: 0;
    top: 0;
}

.rules__close {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 1001;
}

.rules__title {
    color: #DEB75D;
    font-family: "Times New Roman";
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.rules__title_big {
    font-size: 52px;
    line-height: 54px;
    letter-spacing: 2.08px;
}

.rules__title_middle {
    font-size: 26px;
    line-height: 33px;
    letter-spacing: 1.56px;

    padding-bottom: 40px;
}

.rules__text {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.rules__text-block {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.rules__text-title {
    color: #FFF;
    font-family: "Times New Roman";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 122.222% */
    letter-spacing: 1.08px;
    text-transform: uppercase;
}

.rules__text-title_first {
    max-width: 250px;
}

.rules__text-block_base {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.rules__text_base {
    color: #ABADB7;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.rules__text_base_yellow {
    color: #DEB75D;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.rules__text_base_white {
    color: #fff;
    font-family: Manrope;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.rules__comfort-rest {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
}

.rules__comfort-rest__variant {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.rules__comfort-rest__text_base {
    display: flex;
    flex-direction: column;
}

.rules__comfort-rest__text_yellow {
    color: #DEB75D;
    text-align: right;
    font-family: Times;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.rules__comfort-rest__text_base span {
    color: #ABADB7;
    font-family: Manrope;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    /* 120% */
}

.rules__signature {
    display: flex;
    gap: 21px;
}

.rules__signature span {
    color: #FFF;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
    text-transform: none;
}

.rules__signature p {
    color: #DEB75D;
    font-family: "Times New Roman";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 112.5% */
    letter-spacing: 0.96px;
    text-transform: uppercase;

    display: flex;
    flex-direction: column;
    gap: 8px;
}