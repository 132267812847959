.loading {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    /* justify-content: flex-end; */

    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 60px 0;
    /* justify-content: center; */
    justify-content: space-between;
}

.loading__tang-logo {
    width: 234px;
    height: 68px;
    flex-shrink: 0;
}

.loading__title {
    margin: 51px 0 0 0;
    z-index: 5;

    color: #DEB75D;
    font-family: "Times New Roman";
    font-size: 80px;
    font-style: normal;
    font-weight: 400;
    line-height: 84px;
    /* 105% */
    letter-spacing: 4.8px;
    text-transform: uppercase;
}

.loading__logo-and-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 133px;
    margin-bottom: 200px; */
    position: relative;
}

.loading__logo {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;

    width: 390px;
    height: 344px;
    top: -185px;
    left: auto;
}

.loading__shadow {
    position: absolute;

    width: 288px;
    height: 10px;
    top: 461px;
}

.loading__star-bg {
    position: absolute;
    top: 0;
    /* left: -60px; */
    z-index: 0;

    display: flex;
    align-items: center;
    justify-content: center;
}

.loading__line {
    margin-top: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 180px;
    margin-bottom: -50px;
    z-index: 0;
}

.loading__line__box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* margin: 0 0 -200px 0; */
    overflow: hidden;
}

.loading__terms {
    color: #7D808F;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    margin: 0;
    position: relative;
    z-index: 2;
    /* padding: 0 0 30px 0; */
}

.loading__terms-promo-link {
    color: #DEB75D;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.loading__progress-box {
    width: 270px;
    height: 2px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.3);
    margin: 20px 0 0;
}

.loading__progress {
    transition: all 0.1s ease-in-out;
    height: 100%;
    border-radius: 16px;
    background-color: #DEB75D;
}

.loading__perent {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 133.333%;
    letter-spacing: 0.36px;
    text-transform: uppercase;
    margin: 12px 0 0;
    color: #FFF;
}

.loading__blue {
    left: 0;
    position: absolute;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 214px;
    background: linear-gradient(180deg, rgba(30, 30, 45, 0.00) 2.68%, #1E1E2D 57.3%);

    display: flex;
    justify-content: center;
    align-items: center;
}