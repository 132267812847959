.subcat-block {
  width: 100%;
  border-top: 1px solid rgba(138, 138, 163, 0.3);
  box-sizing: border-box;
  padding-top: 32px;
  scroll-margin-top: 120px;
}

.subcat-block:first-of-type {
  border-top: none;
  padding-top: 0;
}

.subcat-block__title-box {
  display: flex;
  align-items: center;
  gap: 8px;
}

.subcat-block__icon {
  width: 26px;
  aspect-ratio: 1/1;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

.subcat-block__title {
  color: #7D808F;
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  /* 216.667% */
}

.subcat-block__products {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}