.order-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 12px;
  background: #25252B;
  box-sizing: border-box;
  padding: 16px 12px 20px;
}

.order-card__heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card__title-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.order-card__arrow {
  width: 10px;
  height: 8px;
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;
}

.order-card__arrow_rotate {
  transform: rotate(0deg);
}

.order-card__arrow-fill {
  fill: #DEB75D;
  transition: fill 0.2s ease-in-out;
}

.order-card__arrow_rotate .order-card__arrow-fill {
  fill: #8a8aa3;
}

.order-card__date {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.48px;
  transition: color 0.2s ease-in-out;
}

.order-card__date_opened {
  color: #8a8aa3;
}

.order-card__repeat-btn {
  width: fit-content;
  display: flex;
  color: #DEB75D;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  border-radius: 80px;
  background: #333348;
  box-sizing: border-box;
  padding: 8px 14px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  transition: all 0.2s ease-in-out;
}

.order-card__repeat-btn_visible {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  user-select: auto;
}

.order-card__items {
  width: 100%;
}

.order-card__items-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-top: 1px solid rgba(138, 138, 163, 0.2);
  border-bottom: 1px solid rgba(138, 138, 163, 0.2);
  box-sizing: border-box;
  padding: 16px 0;
  margin: 16px 0;
}

.order-card__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order-card__item-heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card__item-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.64px;
}

.order-card__item-price {
  color: #c3a08d;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.order-card__item-price_size_small {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.48px;
}

.order-card__total {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order-card__total-text {
  color: #8a8aa3;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.64px;
}

.order-card__total-text_opened {
  color: #fff;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
}

.order-card__total-price {
  color: #8a8aa3;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.order-card__total-price_opened {
  color: #fff;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: 0.88px;
}

.order-card__total-price_size_medium {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.72px;
}

.order-card__total-price_size_small {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.48px;
}
