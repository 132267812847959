.terms__box {
    color: #7D808F;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    margin: 0;
    position: relative;
    z-index: 2;
    /* padding: 0 0 30px 0; */
}

.terms__text {
    color: #DEB75D;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}