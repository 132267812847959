.cart-second-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100vh;
}

.cart-second-step__super {
    color: #DEB75D;
    text-align: center;

    font-family: "Manrope";
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 114.286% */
    letter-spacing: 1.12px;
    text-transform: uppercase;
    margin-top: 30px;
}

.cart-second-step__title {
    color: #FFF;
    text-align: center;

    font-family: "Manrope";
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    margin-top: 8px;
    /* 122.222% */
    
}

.cart-second-step__subtitle {
    color: #ABADB7;
    text-align: center;
    font-family: "Manrope";
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 14px;
    /* 116.667% */
    max-width: 280px;
    margin-top: 8px;
}