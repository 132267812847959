.waiter-conf {
    position: fixed;
    bottom: 0;
    left: auto;
    width: 100vw;
    max-width: 500px;
    height: 250px;
    border-radius: 24px 24px 0px 0px;
    background: #0D0D0D;

    padding: 16px 18px 32px 18px;
    /* max-width: 390px; */
    box-sizing: border-box;
    z-index: 100;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.waiter-conf__confirm {
    position: fixed;
    bottom: 0;
    left: auto;
    width: 100vw;
    max-width: 500px;
    height: 250px;
    border-radius: 24px 24px 0px 0px;
    background: #0D0D0D;

    padding: 16px 18px 32px 18px;
    /* max-width: 390px; */
    box-sizing: border-box;
    z-index: 110;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.waiter-conf__confirm__text {
    color: #ABADB7;
    text-align: center;
    font-family: "Manrope";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    /* 112.5% */
    margin-top: 12px;
    max-width: 250px;
}

.waiter-conf__title {
    color: #DEB75D !important;
    margin-top: 12px;
}

.waiter-conf__text {
    color: #ABADB7;
    text-align: center;
    font-family: "Manrope";
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    /* 114.286% */
    margin-top: 8px;
}

.waiter-conf__confirm-btn {
    border-radius: 12px;
    border: 2px solid #DEB75D;
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #DEB75D;
    font-family: "Manrope";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */
    margin-top: 32px;
}

.waiter-conf__confirm__confirm-btn {
    border-radius: 12px;
    background: #25252B;

    display: flex;
    width: 198px;
    height: 60px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    color: #DEB75D;
    font-family: "Manrope";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 109.091% */
    margin-top: 52px;
}