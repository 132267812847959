@import url(./assets/fonts/NunitoSans/stylesheet.css);
@import url(./assets/fonts/Mayak/stylesheet.css);
@import url(./assets/fonts/Manrope/stylesheet.css);

body {
  margin: 0;
  font-family: 'Manrope', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-color);
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p, h1, h2, h3, h4, h5, h6, blockquote {
  padding: 0;
  margin: 0;
  white-space: pre-line;
}

a {
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

button {
  font-family: 'Manrope', 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--text-color-yellow);
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

input, textarea {
  color: var(--text-color);
  background-color: transparent;
  border: none;
  outline: transparent;
  padding: 0;
}

:root {
  --text-color: #FFFFFF;
  --dark-text-color: #ABADB7;
  --text-color-yellow: #DEB75D;

  --bg-color: #0D0D0D;
}