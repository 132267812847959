.catalog {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--bg-color);
    /* justify-content: flex-end; */

    position: relative;
    /* max-height: 844px; */
    /* overflow-x: hidden; */
    overflow: scroll;
    padding: 60px 0 60px 0;
    max-width: 500px;
}

.catalog .loading__line__box {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* margin: 70px 0 0 0; */
    max-height: 214px;
    overflow: hidden;
}

.catalog .loading__line {
    margin-bottom: -197px;
}

.catalog .header-title {
    padding: 13px 0;
    margin: 0px -1px 0 0;
    height: 33px;
    display: flex;
    align-items: center;
}

.catalog__logo {
    width: 96px;
    height: 138px;
    z-index: 2;
    /* margin-left: -10px; */
    margin-top: 20px;
}

.catalog__line {
    /* margin-bottom: -199px;
    margin-top: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 180px; */

    margin-bottom: -199px;
    margin-top: 30px;
    overflow: hidden;
    width: 100%;
    max-width: 180px;
}

.catalog__title-menu__box {
    display: flex;
    flex-direction: column;
    /* gap: 25px; */
    margin-top: 80px;
    z-index: 2;
}

.catalog__conf-and-term {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    max-height: 220px;
    margin-top: 43px;
}

.catalog__title-menu {
    color: #DEB75D;
    text-align: center;
    font-family: "Times New Roman";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 52px;

    letter-spacing: 2.08px;
    text-transform: uppercase;
    z-index: 2;
}

.catalog__title-menu_rules {
    cursor: pointer;
}

.catalog__block-button__box {
    display: flex;
    gap: 12px;
    justify-content: center;
}

.catalog__block-button__box_disable {
    opacity: 0;
    pointer-events: none;
    user-select: none;
}

.catalog__block-button {
    display: flex;
    width: 100%;
    height: 100%;

    max-width: 64px;
    max-height: 70px;

    padding: 16px 18px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    justify-content: center;

    border-radius: 6px;
    border: 1px solid #DEB75D;
}

.catalog__block-button__title {
    color: #FFF;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    /* 116.667% */
}

.catalog .terms__box {
    color: #7D808F;
    text-align: center;
    font-family: Manrope;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 133.333% */
    margin: 0;
    position: relative;
    z-index: 2;
    margin-top: 50px;
    margin-bottom: 30px;
}

.catalog__bonus-card {
    display: flex;
    gap: 8px;
    margin-top: 45px;
}

.catalog__bonus-card span {
    color: var(--text-color-yellow);
}