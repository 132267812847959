.bell-button {
  position: fixed;
  right: auto;
  bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  max-width: 500px;
  z-index: 3;

}

.bell-button__box {
  width: 64px;
  height: 64px;
  background: #DEB75D;
  border-radius: 600px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 20px;
}

.bell-button_disabled {
  display: none;
}