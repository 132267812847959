.product-card {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 28px;
  align-items: start;
  gap: 46px;
}

.product-card__title {
  color: #FFF;
  font-family: "Times New Roman";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  /* 155.556% */
  letter-spacing: 0.72px;
  text-transform: uppercase;
}

.product-card__title__size {
  text-transform: lowercase;
  font-size: 14px;
}

.product-card__text {
  color: #ABADB7;
  font-family: 'Manrope';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 114.286% */
  margin-top: 6px;
}

.product-card__price {
  color: #DEB75D;
  font-family: "Times New Roman";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 120% */
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin-top: 12px;
}

.product-card__price_size_small {
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0.56px;
  margin-left: 4px;
}

.product-card__add-btn {
  width: 28px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.product-card__add-btn_add:active {
  opacity: 0;
}

.product-card__add-btn_checked:active {
  opacity: 0;
}

.product-card__btn-icon {
  width: 100%;
  height: 100%;
}